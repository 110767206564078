import * as React from 'react';

interface DragDropAreaContext {
	hover: React.MutableRefObject<Map<string, number>>;
}

type OwnProps = Pick<React.PropsWithChildren<unknown>, 'children'>;

const Context = React.createContext<DragDropAreaContext>({ hover: { current: new Map<string, number>() } });

export function useDragDropArea(): React.MutableRefObject<Map<string, number>> {
	const context: DragDropAreaContext = React.useContext<DragDropAreaContext>(Context);
	if (!context) throw new Error('useDragDrop() should be used in a child of DragDropArea');

	return context.hover;
}

export const DragDropArea: React.FC<OwnProps> = (props: OwnProps) => {
	const ref = React.useRef<Map<string, number>>(new Map<string, number>());
	const context: DragDropAreaContext = React.useMemo(() => ({
		hover: ref,
	}), [ref]);

	return (
		<Context.Provider value={context}>
			{props.children}
		</Context.Provider>
	);
};
