import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import { Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';

import { DeliveryType } from '@app/objects/Pet';
import { toWords } from '@app/components/Utils/Utils';
import { getOrderSuffix } from '@app/utilities/naturalLanguage';
import { DiscountType, Price, PriceKind } from '@app/objects/Price';
import { WeightUnits } from '@app/objects/Crematory';

const productTitle = (record: Price): string => {
	let text;

	if (record.inventoryItemId === null) {
		if (record.priceKind === PriceKind.ProductPrice) {
			text = 'No Product';
		} else if (record.priceKind === PriceKind.UrnPrice) {
			text = 'No Urn';
		}
	} else if (record.inventoryItem !== null) {
		text = record.name ?? record.inventoryItem.name;
	} else {
		text = record.name ?? 'Unknown product';
	}

	return text;
};

export function renderDetails(record: Price): string {
	if (record.priceKind === PriceKind.UrnPrice || record.priceKind === PriceKind.ProductPrice) return productTitle(record);

	return record.name ?? 'Unknown service';
}

export function getPriceValue(record: Price) {
	const value = record.value;
	const isPercent = record.unit && record.unit === DiscountType.Percentage;
	if (!isPercent) {
		return value >= 0 ? `$${value.toFixed(2)}` : `-$${(-value).toFixed(2)}`;
	}

	return `${value.toFixed(1)}%`;
}

const baseColumns = (weightUnit: WeightUnits): Array<ColumnProps<Price>> => {
	const unit = WeightUnits[weightUnit]?.slice(0, -1).toLowerCase();

	return TableBuilder.shape<Price>()
		.addColumn({
			title: 'Species',
			dataIndex: 'petSpecieId',
			render: (petSpecieId: Nullable<number>, record: Price) => {
				const specie = petSpecieId ? record.petSpecie?.name ?? 'Unknown specie' : 'All';

				return (
					<MobileCell caption="Species">{specie}</MobileCell>
				);
			},
		})
		.addColumn({
			title: 'Weight from',
			dataIndex: 'from',
			align: 'right',
			render: (from: number) => <MobileCell caption="Weight from">{from}</MobileCell>,
		})
		.addColumn({
			title: 'Weight to',
			dataIndex: 'to',
			align: 'right',
			render: (to: number) => <MobileCell caption="Weight to">{to}</MobileCell>,
		})
		.addColumn({
			title: 'Price',
			dataIndex: 'price',
			align: 'right',
			render: (_, record: Price) => (
				<MobileCell caption="Price">{getPriceValue(record)}</MobileCell>
			),
		})
		.addColumn({
			title: `Price per ${unit}`,
			dataIndex: 'extra',
			align: 'right',
			render: (extra: number) => <MobileCell caption={`Price per ${unit}`}>${extra.toFixed(2)}</MobileCell>,
		})
		.build();
};

const deliveryColumns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Delivery Type',
		dataIndex: 'deliveryType',
		render: (value: DeliveryType) => (
			<MobileCell caption="Delivery Type">
				{value !== null ? toWords(DeliveryType[value]) : 'Unknown delivery type'}
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Price',
		dataIndex: 'price',
		align: 'right',
		render: (_, record: Price) => (
			<MobileCell
				caption="Price"
			>
				{getPriceValue(record)} {record.extra ? `($${record.value.toFixed(2)})` : null}
			</MobileCell>
		),
	})
	.build();

const engravingColumns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Name',
		dataIndex: 'order',
		render: (value: Nullable<number>) => {
			const order = (value ?? 0) + 1;

			return (
				<MobileCell caption="Maximal line length">
					{`${order}${getOrderSuffix(order)} line`}
				</MobileCell>
			);
		},
	})
	.addColumn({
		title: 'Maximal line length',
		dataIndex: 'maxSize',
		align: 'right',
		render: (value: number) => (
			<MobileCell caption="Maximal line length">
				{value}
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Price',
		dataIndex: 'price',
		align: 'right',
		render: (_, record: Price) => (
			<MobileCell
				caption="Price"
			>
				{getPriceValue(record)} {record.extra ? `($${record.value.toFixed(2)})` : null}
			</MobileCell>
		),
	})
	.build();

const discountColumns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (value: string) => (
			<MobileCell caption="Maximal line length">
				{value ?? 'Unknown discount'}
			</MobileCell>
		),
	})
	.addColumn({
		title: 'Apply to',
		dataIndex: 'applyTo',
		render: (value: Nullable<PriceKind>) => {
			if (value === null) return null;

			return (
				<MobileCell caption="Apply to">{toWords(PriceKind[value])}</MobileCell>
			);
		},
	})
	.addColumn({
		title: 'Value',
		dataIndex: 'price',
		align: 'right',
		render: (_, record: Price) => (
			<MobileCell caption="Value">
				{getPriceValue(record)} {record.extra ? `($${record.value.toFixed(2)})` : null}
			</MobileCell>
		),
	})
	.build();

const productsColumns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (_, record: Price) => <MobileCell caption="Name">{renderDetails(record)}</MobileCell>,
	})
	.addColumn({
		title: 'Batch Count',
		dataIndex: 'batchCount',
		align: 'right',
		render: (count: number) => <MobileCell caption="Batch Count">{count}</MobileCell>,
	})
	.addColumn({
		title: 'Batch Price',
		dataIndex: 'batchPrice',
		align: 'right',
		render: (price: number) => <MobileCell caption="Batch Price">${price.toFixed(2)}</MobileCell>,
	})
	.addColumn({
		title: 'Price per item over batch',
		dataIndex: 'price',
		align: 'right',
		render: (_, record: Price) => (
			<MobileCell caption="Price per item over batch">
				{getPriceValue(record)} {record.extra ? `($${record.value.toFixed(2)})` : null}
			</MobileCell>
		),
	})
	.build();

const specialServiceColumns: Array<ColumnProps<Price>> = [
	...productsColumns,
	...TableBuilder.shape<Price>()
		.addColumn({
			title: 'Default',
			dataIndex: 'isDefault',
			align: 'center',
			render: (isDefault: boolean) => {
				if (!isDefault) return null;

				return (
					<MobileCell caption="Default" fullWidth>
						<CheckOutlined style={{ color: 'green' }} />
					</MobileCell>
				);
			},
		}).build(),
];

const pickupServicesColumns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: (_, record: Price) => <MobileCell caption="Name">{renderDetails(record)}</MobileCell>,
	})
	.addColumn({
		title: 'Value',
		dataIndex: 'price',
		align: 'right',
		render: (_, record: Price) => (
			<MobileCell caption="Value">
				{record.value.toFixed(2)}
			</MobileCell>
		),
	})
	.build();

const rushFeeColumns: Array<ColumnProps<Price>> = TableBuilder.shape<Price>()
	.addColumn({
		title: 'Name',
		dataIndex: 'name',
		render: () => <MobileCell caption="Name">Price of Rush Fee</MobileCell>,
	})
	.addColumn({
		title: 'Value',
		dataIndex: 'price',
		align: 'right',
		render: (_, record: Price) => (
			<MobileCell caption="Value">
				{record.value.toFixed(2)}
			</MobileCell>
		),
	})
	.build();

export const columns = (weightUnit: WeightUnits): Record<PriceKind, Array<ColumnProps<Price>>> => ({
	[PriceKind.BasePrice]: baseColumns(weightUnit),
	[PriceKind.DeliveryPrice]: deliveryColumns,
	[PriceKind.Discount]: discountColumns,
	[PriceKind.Engraving]: engravingColumns,
	[PriceKind.ProductPrice]: productsColumns,
	[PriceKind.SpecialServicePrice]: specialServiceColumns,
	[PriceKind.PickupPrice]: pickupServicesColumns,
	[PriceKind.UrnPrice]: productsColumns,
	[PriceKind.RushFee]: rushFeeColumns,
	[PriceKind.ClinicDiscount]: [],
});
