import * as React from 'react';
import { useSelector } from 'react-redux';

import { isPresent, Nullable } from '@common/typescript/objects/Nullable';

import { Translator } from '@app/internationalization/init';
import { Location, locationName } from '@app/objects/Location';
import { ViewMode, toWords } from '@app/components/Utils/Utils';
import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';

import {
	getServiceTypeName,
	ServiceType,
	DeliveryType,
	PetOnHold, PetEngraving,
} from '@app/objects/Pet';
import { Clinic } from '@app/objects/Clinic';
import { AddressDetails } from '@app/services/AddressService';
import { ApplicationState } from '@app/store';
import { CrematoryListInfo } from '@app/objects/Crematory';
import { australiaCode } from '@app/components/UI/Inputs/PhoneInput';

export interface BaseSectionProps {
	mode: ViewMode;
	crematoryId: number;
	crematory?: CrematoryListInfo;
}

export function getPetInfoFields(pet: FormValues, t: Translator, showInternalId: boolean) {
	const setTagId = { title: 'Tag Id', data: pet.internalIdNum };
	const engraving = (pet.engraving ?? [])
		.filter((q: PetEngraving) => Boolean(q.name))
		.sort((a: PetEngraving, b: PetEngraving) => a.order - b.order)
		.map((q: PetEngraving) => (<li key={q.id}>{q.name}</li>));
	const setEngraving = { title: t('engraving'), data: <ul className="">{engraving}</ul> };

	return [
		{ title: t('name'), data: pet.name },
		{ title: t('gender'), data: pet.gender },
		{ title: t('weight'), data: pet.reportedWeight },
		{ title: t('species'), data: pet.petSpecie },
		{ title: t('breed'), data: pet.petBreed },
		{ title: t('breed-type'), data: pet.isMixed ? 'Mixed' : 'Pure' },
		{ title: t('color'), data: pet.color },
		showInternalId && pet.serviceType !== ServiceType.Communal ? setTagId : undefined,
		pet.serviceType === ServiceType.Communal ? undefined : setEngraving,
	];
}

export function getOwnerInfoFields(pet: FormValues, t: Translator) {
	return [
		{ title: t('owner-type'), data: pet.discount ?? 'Regular' },
		{ title: t('owner-first-name'), data: pet.ownerFirstName },
		{ title: t('owner-last-name'), data: pet.ownerLastName },
	];
}

const keyPrefix = 'editors.clinic-pet-editor.cards.cremation-info';
export function getCremationInfo(pet: FormValues, t: Translator, clinic: Nullable<Clinic>, locations: Array<Location>) {
	const onHold = { title: 'On hold', data: pet.onHold === PetOnHold.ByClinic ? 'Pet is on hold' : 'Pet is not on hold' };
	const onRush = { title: 'Rush', data: pet.rush ? 'Pet processing is urgent' : 'Pet processing is normal' };
	const setDelivery = { title: 'Delivery Type', data: isPresent(pet.deliveryType) ? toWords(DeliveryType[pet.deliveryType]) : '-' };
	const location = locations.find((item: Location) => item.id === pet.clinicLocationId);
	const isAustralia = clinic?.country?.phoneCode === australiaCode;

	return [
		{ title: t(`${keyPrefix}.cremation-type`), data: getServiceTypeName(pet.serviceType!, t, pet.isSpecial, isAustralia, pet.rush) },
		{ title: t(`${keyPrefix}.clinic-location`), data: location ? locationName(location) : 'None' },
		clinic?.canSetDeliveryType ? setDelivery : undefined,
		clinic?.canPutOnHold ? onHold : undefined,
		clinic?.canPutOnRush ? onRush : undefined,
	];
}

const deliveryKeyPrefix = 'editors.pet-editor.labels';
export function getDeliveryInfoFields(pet: FormValues, t: Translator, details: AddressDetails) {
	const states = useSelector((state: ApplicationState) => state.selects.regions.items);
	const state = pet.deliveryState ?? states.find((i) => i.id === pet.deliveryStateId);

	return [
		{ title: t(`${deliveryKeyPrefix}.delivery-address-1`), data: pet.deliveryAddress },
		{ title: t(`${deliveryKeyPrefix}.delivery-address-2`), data: pet.deliveryAddress2 },
		{ title: t(`${deliveryKeyPrefix}.delivery-city`), data: pet.deliveryCity },
		{ title: t(`${deliveryKeyPrefix}.delivery-${details.mailKey}`), data: pet.deliveryZip },
		{ title: t(`${deliveryKeyPrefix}.delivery-${details.regionKey}`), data: state?.name },
	];
}
