import { FormikProps } from 'formik';

import { WithRemoved } from '@common/typescript/objects/WithRemoved';
import { WithId } from '@common/typescript/objects/WithId';
import { Nullable } from '@common/typescript/objects/Nullable';

import { isWithOrder, reorder, WithOrder } from '@app/objects/WithOrder';

type Entity = WithId & WithRemoved;

export class FormikListManager<TForm, T extends Entity> {
	private readonly formik: FormikProps<TForm>;
	private list: Array<T> = [];
	private key: Nullable<string> = null;

	constructor(formik: FormikProps<TForm>) {
		this.formik = formik;
	}

	public static wrap<TForm, T extends Entity>(formik: FormikProps<TForm>): FormikListManager<TForm, T> {
		return new FormikListManager<TForm, T>(formik);
	}

	public for(key: string): FormikListManager<TForm, T> {
		this.key = key;
		this.list = [...this.formik.getFieldMeta(key).value as Array<T>];

		return this;
	}

	public add(item: T): FormikListManager<TForm, T> {
		this.list = [item, ...this.list];

		return this;
	}

	public push(item: T): FormikListManager<TForm, T> {
		const index = this.list.findIndex((item: T) => item.removed);
		if (index >= 0) {
			this.list = [...this.list.slice(0, index), item, ...this.list.slice(index)];
		} else {
			this.list = [...this.list, item];
		}

		if (isWithOrder(item)) {
			this.list = reorder(this.list as unknown as Array<WithOrder>) as unknown as Array<T>;
		}

		return this;
	}

	public remove(index: number): FormikListManager<TForm, T> {
		if (index >= this.list.length) return this;

		const item = this.list[index];
		const result = this.list.filter((item: T, id: number) => id !== index);
		this.list = result;

		if (item.id <= 0) return this;

		result.push({ ...item, removed: true });

		return this;
	}

	public set(index: number, item: T | ((value: T) => T)): FormikListManager<TForm, T> {
		if (index >= this.list.length) return this;

		this.list[index] = item instanceof Function ? item(this.list[index]) : item;

		return this;
	}

	public apply(): void {
		if (!this.key) return;

		this.formik.setFieldValue(this.key, this.list, false);
	}
}
