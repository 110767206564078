import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

import {
	Form,
	Formik,
	FormikHelpers,
	FormikProps,
} from 'formik';

import { v4 } from 'uuid';

import { Nullable } from '@common/typescript/objects/Nullable';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { ResponseError } from '@common/react/components/Api/RequestError';
import { AlertMessage, AlertType } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { ApplicationState } from '@app/store';
import { PriceKind } from '@app/objects/Price';
import { useRequest } from '@app/hooks/useRequest';
import { useCrematory } from '@app/hooks/useCrematory';
import { ViewMode } from '@app/components/Utils/Utils';
import { getItemActionCreators } from '@app/store/Item';
import {
	getMode,
	servicesDiff,
	setDefaultDeliveryType,
	toCreateMessage,
	toUpdateMessage,
} from '@app/components/Pages/ClinicPetEditor/services';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { IdMessage } from '@app/objects/Requests/IdMessage';
import { Clinic, getDefaultClinic } from '@app/objects/Clinic';
import { alertMessage, MessageType } from '@app/utilities/alert';
import { TrackChange } from '@app/smart components/Tracker/TrackChange';
import { ClinicPet, getDefaultClinicPet, PetPrice } from '@app/objects/Pet';
import { NEW_PET_ID } from '@app/components/Pages/ClinicPetEditor/ClinicApi';
import {
	CreateMessage,
	FormValues,
	Props,
	RequestMessageType,
} from '@app/components/Pages/ClinicPetEditor/types';
import { getValidationSchema } from '@app/components/Pages/ClinicPetEditor/schema';
import { clinicStoreName, getClinicEndpoint } from '@app/components/Pages/Clinics/ClinicApi';
import { ClinicPetForm } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ClinicPetForm';
import { allowAdd } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';

import '@app/scss/pages/editor.scss';
import { StoreEntryNode } from '@app/objects/StoreEntry';
import { diff, timeoutCode } from '@app/components/Pages/PetEditor/OldPetEditor/Services';

const additionalParams = {
	withCrematories: true,
	withCountry: true,
	withLanguage: true,
};

const defaultClinic: Clinic = getDefaultClinic();

function initUrn(item: PetPrice): PetPrice {
	const result: PetPrice = {
		...item,
		clientId: v4(),
	};

	const node = item.node;
	if (!node) return result;

	if (!node.entry) return result;

	const entry = { ...node.entry };
	result.entry = entry;

	if (!entry.tree.find((node: StoreEntryNode) => node.id === item.node?.id)) {
		entry.tree.push(node);
	}

	entry.tree = entry.tree.map((item: StoreEntryNode): StoreEntryNode => ({
		...item,
		parent: null,
		children: [],
		entry: null,
	}));
	result.node = entry.tree.find((node: StoreEntryNode) => node.id === item.nodeId) ?? null;
	result.toSelect = !allowAdd(entry, result.nodeId);

	return result;
}

function initUrns(item: ClinicPet): FormValues['urns'] {
	return item.services
		.filter((record: PetPrice) => record.price?.priceKind === PriceKind.UrnPrice)
		.map(initUrn);
}

const getInitialItems = (item: Nullable<ClinicPet>, defaultDeliveryClinicId?: Nullable<number>): FormValues => {
	if (item) {
		return {
			id: item.id,
			name: item.name,
			engraving: item.engraving,

			ownerFirstName: item.ownerFirstName,
			ownerLastName: item.ownerLastName,
			ownerEmail: item.ownerEmail,
			ownerPhone: item.ownerPhone,

			petSpecieId: item.petSpecieId,
			petSpecie: item.petSpecie,

			petBreedId: item.petBreedId,
			petBreed: item.petBreed,
			isMixed: item.isMixed,

			petSource: item.petSource,

			color: item.color,

			genderId: item.genderId,
			gender: item.gender,

			reportedWeight: item.reportedWeight,
			serviceType: item.serviceType >= 0 ? item.serviceType : NEW_PET_ID,
			isSpecial: item.isSpecial,

			clinicLocationId: item.clinicLocationId,
			clinicLocation: item.clinicLocation,
			deliveryClinicId: item.deliveryClinicId,

			discountId: item.discountId,
			discount: item.discount,

			services: item.services
				.filter((q) => q.price?.priceKind === PriceKind.SpecialServicePrice)
				.map((item: PetPrice) => ({
					...item,
					clientId: v4(),
				})),

			noAshes: item.noAshes,
			specialInstructions: item.specialInstructions,

			onHold: item.onHold,
			rush: item.rush,
			isEditable: item.isEditable,
			urns: initUrns(item),
			products: item.services
				.filter((i: PetPrice) => i.price?.priceKind === PriceKind.ProductPrice)
				.map((q: PetPrice) => ({
					...q,
					clientId: v4(),
				})),
			loadingUrns: false,

			deliveryType: item.deliveryType,
			deliveryAddress: item.deliveryAddress ?? '',
			deliveryAddress2: item.deliveryAddress2 ?? '',
			deliveryCity: item.deliveryCity ?? '',
			deliveryZip: item.deliveryZip ?? '',
			deliveryStateId: item.deliveryStateId,
			deliveryState: item.deliveryState,

			internalIdNum: item.internalIdNum,
			files: item.files ?? [],
		};
	}

	return {
		...getDefaultClinicPet(),
		deliveryClinicId: defaultDeliveryClinicId ?? null,
		loadingUrns: false,
		genderId: undefined,
		deliveryType: undefined,
		serviceType: undefined,
		products: [],
		urns: [],
	};
};

function isNew(id: string): boolean {
	const value = Number(id);

	return Number.isNaN(value) || value <= 0;
}

export function useClinicRequest(defaultClinic: Clinic) {
	const dispatch = useDispatch();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const action = bindActionCreators(getItemActionCreators(), dispatch);
	const clinic = useSelector((state: ApplicationState) => state.clinic.item);

	React.useEffect(() => {
		action.loadItem(clinicStoreName, getClinicEndpoint, Number(user?.clinicId), defaultClinic, additionalParams);
	}, [user?.clinicId]);

	return clinic;
}

function usePetRequest(id: string) {
	const request = useRequest<ClinicPet, IdMessage>('getClinicPet', undefined, { requestOnMount: false });
	const [item, setItem] = React.useState<Nullable<ClinicPet>>(() => null);

	React.useEffect(() => {
		if (!isNew(id)) {
			request.reload({ id: Number(id) });
		}
	}, [id]);

	React.useEffect(() => {
		setItem(request.item);
	}, [request.item]);

	return {
		item,
		setItem,
		loading: request.loading,
		error: request.error,
	};
}

interface ServiceComponentProps {
	error: Nullable<string>;

}

const ServiceComponent: React.FC<ServiceComponentProps> = ({ error }: ServiceComponentProps) => {
	if (error) return <AlertMessage type={AlertType.Danger} message={error} />;

	return <Loading />;
};

export const ClinicPetEditor = (props: Props): JSX.Element => {
	const { type, id } = props.match.params;
	const [areImportantChanges, setAreImportantChanges] = React.useState<boolean>(false);
	const formRef = React.useRef<FormikProps<FormValues>>(null);
	const idempotencyTokenRef = React.useRef<string | undefined>(undefined);
	const valuesRef = React.useRef<Nullable<CreateMessage>>(null);
	const history = useHistory();

	const submitRequest = useRequest<ClinicPet, RequestMessageType>(
		isNew(id) ? 'createClinicPet' : 'updateClinicPet',
		undefined,
		{ requestOnMount: false, idempotencyToken: idempotencyTokenRef.current },
	);
	const clinic = useClinicRequest(defaultClinic);
	const {
		item, setItem, loading, error,
	} = usePetRequest(id);
	const crematory = useCrematory();
	const defaultDeliveryClinicId = React.useMemo(() => clinic?.clinicCrematories.find((i) => i.crematoryId === crematory?.id)?.deliveryClinicId, [crematory?.id, clinic]);

	const initial: FormValues = getInitialItems(item, defaultDeliveryClinicId);
	const mode: ViewMode = getMode(type, initial.isEditable);

	const onSubmit = (data: RequestMessageType, actions: FormikHelpers<FormValues>) => {
		if (!formRef.current) return;

		const { services, id } = formRef.current.values;

		submitRequest.reload(data)
			.then((item: void | ClinicPet) => {
				if (!item) return;

				actions.resetForm();
				const path = generatePath(RoutePaths.petEditor, { id: item.id, type: 'view' });
				history.replace(path);
				idempotencyTokenRef.current = undefined;
				const araChanges = (initial.onHold && initial.onHold !== item.onHold)
					|| initial.name !== item.name
					|| initial.engraving !== item.engraving
					|| servicesDiff(initial.services, services ?? []);

				if (id > 0) setItem(item);
				if (initial.id > 0 && araChanges) setAreImportantChanges(true);
			})
			.catch((error: ResponseError) => {
				alertMessage(MessageType.error, error.message);

				if (error.code !== timeoutCode) idempotencyTokenRef.current = v4();
			})
			.finally(() => actions.setSubmitting(false));
	};

	React.useEffect(() => {
		if (isNew(id)) idempotencyTokenRef.current = v4();
	}, [id]);

	return (
		<Formik
			initialValues={initial}
			validationSchema={getValidationSchema(crematory)}
			onSubmit={(values, actions) => {
				const data: RequestMessageType = values.id > 0
					? toUpdateMessage(values, initial)
					: toCreateMessage(values);

				if ((formRef.current?.submitCount ?? 0) > 1 && values.id < 0) {
					if (!valuesRef.current) valuesRef.current = { ...data } as CreateMessage;

					const hasDiff = diff(valuesRef.current, data as CreateMessage);

					if (hasDiff) {
						idempotencyTokenRef.current = v4();
						valuesRef.current = { ...data } as CreateMessage;
					}
				}

				onSubmit(data, actions);
			}}
			enableReinitialize
			innerRef={formRef}
		>
			{(props: FormikProps<FormValues>) => (
				<Form>
					<div className="editor-page clinic-pet-page" style={{ paddingBottom: '8px' }}>
						<TrackChange
							track={props.values.serviceType}
							onChange={() => {
								if (!isNew(id)) return;
								if (clinic?.canSetDeliveryType) setDefaultDeliveryType(props, clinic);
							}}
						/>
						{/* Show the loader before we launched the request or just started downloading, show an alert if there is an error */}
						{(item === null && !isNew(id) && !error) || loading || error
							? <ServiceComponent error={error} />
							: <ClinicPetForm mode={mode} petId={Number(id)} areImportantChanges={areImportantChanges} />}
					</div>
				</Form>
			)}
		</Formik>
	);
};
