import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
	Field,
	FieldProps,
	FieldArrayRenderProps,
	FieldArray,
	useFormikContext,
	useField,
} from 'formik';

import List from 'antd/lib/list';
import Table, { ColumnProps } from 'antd/lib/table';
import Tooltip from 'antd/lib/tooltip';
import {
	DeleteOutlined,
	EditOutlined,
	ExclamationCircleOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import Button from 'antd/lib/button';
import Tag from 'antd/lib/tag';

import { isPresent, Nullable } from '@common/typescript/objects/Nullable';
import { List as IList } from '@common/typescript/objects/List';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { AlertMessage, AlertType } from '@common/react/components/UI/AlertMessage/AlertMessage';

import { FormValues } from '@app/components/Pages/ClinicPetEditor/types';
import {
	CardSize,
	Card,
	ListComponent,
	ItemsList,
} from '@app/components/UI/Cards/Card';
import { ViewMode } from '@app/components/Utils/Utils';

import {
	PetPrice,
	ServiceType,
} from '@app/objects/Pet';
import { useModal } from '@app/hooks/Editors/useModal';

import { BaseSectionProps } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/ViewContent';
import { Preview } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/Preview';
import { NumberField } from '@app/components/UI/NumberField/NumberField';
import { useCrematory } from '@app/hooks/useCrematory';
import {
	StoreEntriesUrnModal,
	allowAdd,
} from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/StoreEntriesModal/StoreEntriesModal';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { PreselectStore, urnsError } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/ProcessSection';
import { getAdditionalPrice } from '@app/components/Pages/ClinicPetEditor/ClinicPetsComponents/utils';
import { DefaultUrnProvider } from '@app/components/Pages/PetEditor/OldPetEditor/DefaultUrnProvider';

import '@app/scss/ui/urn-preview.scss';
import { RequestData, useRequest } from '@app/hooks/useRequest';
import { PriceListParams, UrnAttributeSelect } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/UrnSelection';
import { UserRole } from '@app/objects/User';
import { Price } from '@app/objects/Price';
import { StoreEntryAttribute } from '@app/objects/StoreEntry';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';
import { RoleManager } from '@app/services/RoleManager';
import { allowAdd as canAdd } from '@app/components/UI/Status/StatusModal/SpecificContentComponents/UrnForm/Services';
import { ApplicationState } from '@app/store';
import { getUrnSegments } from '@app/components/Pages/PetEditor/OldPetEditor/Services';

export function getUrnColumns(
	translate: (key: string) => string,
	setPreview: (value?: string) => void,
	showPricesToClinic: boolean,
	onPreselect: (value: PreselectStore) => void,
	onOpenModal: () => void,
	onChangeUrn: (value: PetPrice) => void,
	priceList: RequestData<PriceListParams, IList<Price>>,
	role?: UserRole,
): Array<ColumnProps<PetPrice>> {
	return TableBuilder.shape<PetPrice>()
		.addColumn({
			title: <b>Urn</b>,
			dataIndex: 'name',
			width: '30%',
			render: (text: string, record: PetPrice) => {
				const price = record;
				const batchCount: Nullable<number> = price?.batchCount ?? null;
				const batchPrice: Nullable<number> = price?.batchPrice ?? null;
				const withBatch = batchCount !== null && batchPrice !== null;

				const batchDescription = withBatch ? `${batchCount} at $${batchPrice.toFixed(2)}` : null;
				const additionalDescription = `Each additional - $${price?.value.toFixed(2)}`;

				return (
					<MobileCell caption={translate('table.columns.name')}>
						{record.entry?.name ?? 'Unknown Urn'}
						{!allowAdd(record.entry, record.nodeId) && (
							<Tooltip title="Not all parameters for the urn are selected">
								<ExclamationCircleOutlined className="color-red icon-margin" />
							</Tooltip>
						)}
						<div>
							{showPricesToClinic && withBatch && batchCount! > 0
								? <div className="special-service-description">{batchDescription}</div>
								: null}
							{showPricesToClinic && price ? <div className="special-service-description">{additionalDescription}</div> : null}
						</div>
					</MobileCell>
				);
			},
		})
		.addColumn({
			title: <b>Value</b>,
			dataIndex: 'nodeId',
			render: (id: number, record: PetPrice, index: number) => {
				const names = getUrnSegments(record, true);
				const price = priceList.item?.list?.find((i) => i.inventoryItemId === record.node?.itemId) ?? record.price;
				const warning = canAdd(record.entry, record.node, record.nodeId) && !priceList.loading && !price;
				const node = record.node ?? record.entry?.tree.find((i) => i.id === record.nodeId);

				return (
					<MobileCell caption={translate('table.columns.value')}>
						{record.id <= 0 ? (
							<Field name="urns">
								{({ form }: FieldProps<FormValues>) => (
									<List
										size="small"
										dataSource={record.entry?.attributes.filter((i) => i.clinicCanPick)}
										style={{ flex: 1 }}
										renderItem={(item: StoreEntryAttribute) => (
											<List.Item>
												<List.Item.Meta
													title={(
														<ControlLabel
															text={item.name}
															required={!RoleManager.for(role ?? UserRole.Crematory).isClinicGroup() && item.clinicCanPick}
														/>
													)}
												/>
												<UrnAttributeSelect
													attribute={item}
													entry={record.entry!}
													value={record}
													selection={node ?? null}
													onChange={(value) => {
														const newValue = {
															...record,
															node: value,
															nodeId: value?.id ?? null,
														};

														onChangeUrn(newValue);
														form.setFieldValue(`urns.${index}`, newValue);
													}}
												/>
											</List.Item>
										)}
										footer={warning ? (
											<AlertMessage
												type={AlertType.Warning}
												message={warning
													? 'Price for selected set of parameters doesn\'t exist'
													: null}
												style={{ whiteSpace: 'pre-line', padding: 5 }}
											/>
										) : undefined}
									/>
								)}
							</Field>
						) : null}
						{names.length && record.id > 0 ? names.map((cur: string) => (
							<Tag style={{ margin: '0 10px 5px 0' }} key={cur}>
								{cur}
							</Tag>
						)) : null}
					</MobileCell>
				);
			},
		})
		.addColumn({
			title: <b>{translate('editors.pet-editor.labels.urn-count')}</b>,
			dataIndex: 'count',
			width: '60px',
			render: (value: number, record: PetPrice, id: number) => (
				<Field name={`urns.${id}.count`}>
					{(fieldProps: FieldProps<FormValues>) => {
						const error = fieldProps.form.errors.urns?.[id];
						const touched = fieldProps.form.touched.urns?.[id]?.count;

						return (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName={Boolean(fieldProps.form.submitCount) && error && touched && error.count
									? 'table-cell-error'
									: ''}
								render={({ field, form }: FieldProps<number, FormValues>) => (
									<NumberField {...field} disabled={record.done} onChange={(value: number) => form.setFieldValue(field.name, value)} min={1} />
								)}
							/>
						);
					}}
				</Field>
			),
		})
		.addColumn({
			title: '',
			align: 'right',
			dataIndex: 'image',
			render: (_, record: PetPrice) => {
				const inventoryItem = record.price?.inventoryItem;
				const image = inventoryItem?.originalAvatar ?? inventoryItem?.avatar;

				if (!image) return null;

				return (
					<Button
						type="link"
						className="action-container"
						onClick={() => setPreview(image)}
						icon={<EyeOutlined />}
					/>
				);
			},
		})
		.addColumn({
			title: '',
			align: 'right',
			fixed: 'right',
			width: '80px',
			render: (value, item: PetPrice, id: number) => {
				if (item.done) return null;

				return (
					<FieldArray
						name="urns"
						render={(props: FieldArrayRenderProps) => (
							<Stack direction={StackDirection.Horizontal} gap={0}>
								<Button
									type="link"
									onClick={() => {
										onPreselect({
											storeId: item.entry?.id ?? -1,
											clientId: item.clientId,
											valuesId: [item.nodeId ?? -1],
											count: item.count,
										});
										onOpenModal();
									}}
									icon={<EditOutlined />}
								/>
								<Button
									type="text"
									danger
									onClick={() => {
										if (item.id > 0) {
											props.replace(id, { ...item, removed: true });
											// Move item to the end of the list (so that order of existing items
											// Confirms to order of visible items
											props.move(id, props.form.values.urns.length - 1);
										} else {
											props.remove(id);
										}
									}}
									icon={<DeleteOutlined />}
								/>
							</Stack>
						)}
					/>
				);
			},
		})
		.build();
}

export function getUrns(urns: Array<PetPrice>): Array<PetPrice> {
	return (urns || []).filter((q: PetPrice) => !q.removed);
}

export function getUrnsList(pet: FormValues): Array<ItemsList> {
	return getUrns(pet.urns)
		.filter((i) => i.node)
		.map((urn: PetPrice) => ({
			id: urn.id,
			title: urn.node?.fullName ?? 'Unknown Urn',
			data: urn.count,
		}));
}

export const UrnList: React.FC<BaseSectionProps> = ({ mode, crematoryId }: BaseSectionProps) => {
	const modal = useModal(false);
	const { t } = useTranslation();
	const [, meta] = useField<Array<PetPrice>>('urns');
	const crematory = useCrematory();
	const showPricesToClinic = crematory?.showPricesToClinic ?? false;
	const { values, setFieldValue } = useFormikContext<FormValues>();

	const [preview, setPreview] = React.useState<string | undefined>(undefined);
	const [preselect, setPreselect] = React.useState<PreselectStore | undefined>(undefined);
	const [urn, setUrn] = React.useState<PetPrice | null>(null);
	const user = useSelector((state: ApplicationState) => state.login.user);

	React.useEffect(() => {
		if (preselect && !modal.state) setPreselect(undefined);
	}, [modal.state, preselect]);

	const priceListRequest = useRequest<IList<Price>, PriceListParams>('storeEntryPricesList', undefined, { requestOnMount: false });

	const priceListParams = React.useMemo(() => ({
		nodeId: urn?.nodeId ?? null,
		crematoryId: urn?.price?.crematoryId,
		clinicId: urn?.price?.clinicId,
		serviceType: urn?.price?.serviceType,
		priceType: urn?.price?.priceType,
	}), [urn]);

	React.useEffect(() => {
		if (!isPresent(urn?.nodeId)) return;

		priceListRequest.reload(priceListParams);
	}, [urn?.nodeId, priceListParams]);

	return (
		<>
			<DefaultUrnProvider />
			<Card
				name="Urns"
				size={CardSize.Large}
				mode={mode}
				fullWidth
				withBtn={mode === ViewMode.edit}
				onClick={modal.open}
				disabled={crematoryId <= 0}

				editContent={(
					<>
						<Table
							columns={getUrnColumns(t, setPreview, showPricesToClinic, setPreselect, modal.open, setUrn, priceListRequest, user?.role)}
							dataSource={getUrns(values.urns)}
							size="small"
							rowKey="clientId"
							pagination={false}
							className="table-mobile"
							loading={values.loadingUrns}
							title={urnsError(meta.error)}
						/>
						{showPricesToClinic && (
							<div className="additional-amount">
								<span>
									Additional amount: ${getAdditionalPrice(getUrns(values.urns)).toFixed(2)}
								</span>
							</div>
						)}
					</>
				)}

				viewContent={(
					<ListComponent
						list={getUrnsList(values)}
						placeholder="There are no urns"
					/>
				)}
			/>
			<StoreEntriesUrnModal
				modalControl={modal}
				preselect={preselect}
				onChange={(items: Array<PetPrice>) => setFieldValue('urns', items)}
				serviceType={values.serviceType ?? ServiceType.Private}
				showPricesToClinic={showPricesToClinic}
			/>
			<Preview image={preview} onClose={() => setPreview(undefined)} />
		</>
	);
};
