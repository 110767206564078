import React from 'react';

import Search from 'antd/lib/input/Search';
import Table, { ColumnProps } from 'antd/lib/table';

import TableDataProvider, { TableData } from '@common/react/smart components/Table/TableDataProvider';
import { useTableRemoteSorter } from '@common/react/hooks/RemoteData/useTableRemoteSorter';
import { WithId } from '@common/typescript/objects/WithId';

interface ModalCopyContentProps<TEntity> {
	endpoint: string;
	columns: Array<ColumnProps<TEntity>>;
	selectedIds: Array<number>;
	onChange: (value: Array<number>) => void;
	modalState: boolean;
	withSearch?: boolean;
}

export const ModalCopyContent = <TEntity extends WithId>({
	selectedIds, onChange, modalState, endpoint, columns, withSearch = true,
}: ModalCopyContentProps<TEntity>) => {
	const [query, setQuery] = React.useState<string>('');
	const sorter = useTableRemoteSorter<TEntity>();

	const params = React.useMemo(() => ({
		...sorter.sorter,
		filters: {
			name: query,
		},
	}), [query, sorter]);

	const rowSelection = {
		selectedRowKeys: selectedIds,
		onChange: (keys) => onChange(keys),
	};

	React.useEffect(() => onChange([]), [modalState]);

	return (
		<TableDataProvider
			endpoint={endpoint}
			requestParams={params}
			reloadOnParamsChanged
		>
			{(data: TableData<TEntity>) => (
				<>
					{withSearch && (
						<Search
							allowClear
							placeholder="Search by Name"
							onSearch={(value: string) => setQuery(value)}
							style={{ margin: '10px 0' }}
							enterButton
						/>
					)}
					<Table
						columns={columns}
						dataSource={data.items.list}
						pagination={data.pagination}
						loading={data.isLoading}
						rowKey="id"
						rowSelection={rowSelection}
						onChange={(pagination, filters, sorterResult) => sorter.setSorter(sorterResult)}
						onRow={(record: TEntity) => ({
							onClick: (event) => {
								event.preventDefault();

								const isSelect = selectedIds.includes(record.id);
								if (!isSelect) {
									onChange([...selectedIds, record.id]);
								} else {
									onChange(selectedIds.filter((item) => item !== record.id));
								}
							},
						})}
						className="table-mobile table--row-click"
					/>
				</>
			)}
		</TableDataProvider>
	);
};
