import React from 'react';

import Search from 'antd/lib/input/Search';
import Table, { ColumnProps } from 'antd/lib/table';
import { Field, FieldProps, useFormikContext } from 'formik';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { WithId } from '@common/typescript/objects/WithId';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { MobileCell } from '@common/react/components/Pages/ItemsPage';
import TableDataProvider, { TableData } from '@common/react/smart components/Table/TableDataProvider';

import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';

type TableFormValue = WithId & { name: string; description: string; }

interface RequestParams extends Record<string, unknown>{
	filters: Record<string, unknown>;
}

interface ModalTableProps {
	endpoint: string;
	fieldName: keyof FormValues;
	additionalRequestParams: RequestParams;
}

export const getColumns = (dataIndex: string = 'name'): Array<ColumnProps<TableFormValue>> => TableBuilder.shape<TableFormValue>()
	.addColumn({
		title: 'Name',
		dataIndex,
		render: (text: string) => <MobileCell caption="Name">{text || '-'}</MobileCell>,
	})
	.addColumn({
		title: 'Description',
		dataIndex: 'description',
		render: (text: string) => <MobileCell caption="Description">{text || ''}</MobileCell>,
	})
	.build();

export const handleChecked = (
	isChecked: boolean,
	id: number,
	rowSelection: {
    onChange: (selectedRows: Array<number>) => void;
		onSelectAll: (value: boolean) => void;
    selectedRowKeys: Array<number>;
		selectAll: boolean;
	},
	total: number,
) => {
	const {
		selectedRowKeys,
		selectAll,
		onChange,
		onSelectAll,
	} = rowSelection;

	if ((selectAll && !isChecked) || (!selectAll && isChecked)) {
		let list = [...selectedRowKeys, id];
		if (list.length === total) {
			list = [];
			onSelectAll(!selectAll);
		}

		onChange(list);
	} else if ((!selectAll && !isChecked) || (selectAll && isChecked)) {
		const list = selectedRowKeys.filter((i) => i !== id);
		if (list.length === 0) onSelectAll(selectAll!);

		onChange(list);
	}
};

export const checked = (selectAll: boolean | undefined, values: Array<number>, itemId: number) => {
	let check = false;
	const item = values?.find((i) => i === itemId);

	if ((selectAll && item) || (!selectAll && !item)) check = false;

	if ((selectAll && !item) || (!selectAll && item)) check = true;

	return check;
};

export const getCheckedColumn = (fieldName: string, total: number) => TableBuilder.shape<TableFormValue>()
	.addColumn({
		title: (
			<Field name="selectAll">
				{({ field, form }: FieldProps<boolean, FormValues>) => (
					<Checkbox
						checked={field.value}
						onChange={(event: CheckboxChangeEvent) => {
							form.setFieldValue(field.name, event.target.checked);
							form.setFieldValue(fieldName, []);
						}}
						indeterminate={Boolean((form.values[fieldName] as Array<number>)?.length)}
					/>
				)}
			</Field>
		),
		width: '10ch',
		align: 'center',
		dataIndex: 'check',
		render: (_, record: TableFormValue) => (
			<Field name={fieldName}>
				{({ field, form }: FieldProps<Array<number>, FormValues>) => (
					<Checkbox
						checked={checked(form.values.selectAll, field.value, record.id)}
						onChange={(event: CheckboxChangeEvent) => {
							const isChecked = event.target.checked;

							handleChecked(
								isChecked,
								record.id,
								{
									onChange: (value: Array<number>) => form.setFieldValue(field.name, value),
									onSelectAll: (checked: boolean) => form.setFieldValue('selectAll', checked),
									selectAll: form.values.selectAll,
									selectedRowKeys: field.value,
								},
								total,
							);
						}}
					/>
				)}
			</Field>
		),
	})
	.build();

export const ModalTable: React.FC<ModalTableProps> = ({ endpoint, additionalRequestParams, fieldName }: ModalTableProps) => {
	const { values, setFieldValue } = useFormikContext<FormValues>();
	const [query, setQuery] = React.useState<string>('');

	const reqParams = React.useMemo(() => ({
		...additionalRequestParams,
		filters: {
			...additionalRequestParams.filters,
			name: query,
		},
	}), [additionalRequestParams, query]);

	React.useEffect(() => {
		setFieldValue('filters.name', query);
	}, [query]);

	return (
		<TableDataProvider endpoint={endpoint} requestParams={reqParams} reloadOnParamsChanged>
			{(data: TableData<TableFormValue>) => (
				<Stack direction={StackDirection.Vertical}>
					<Search
						allowClear
						onChange={(event) => {
							if (event.target.value === '' && event.type === 'click' && setQuery) {
								setQuery('');
							}
						}}
						placeholder="Search by Name"
						onSearch={(e: string) => setQuery(e)}
						style={{ padding: '0 0 10px' }}
						enterButton
					/>

					<Table
						columns={[...getCheckedColumn(fieldName, data.items.count), ...getColumns()]}
						dataSource={data.items.list}
						pagination={{ ...data.pagination, hideOnSinglePage: true }}
						loading={data.isLoading}
						rowKey="id"
						className="table-mobile small-padding-table"
						onRow={(record: TableFormValue) => ({
							onClick: () => {
								const items = values[fieldName] as Array<number> ?? [];
								const isChecked = items.includes(record.id);

								handleChecked(
									!isChecked,
									record.id,
									{
										onChange: (value: Array<number>) => setFieldValue(fieldName, value),
										onSelectAll: (checked: boolean) => setFieldValue('selectAll', checked),
										selectAll: values.selectAll,
										selectedRowKeys: items,
									},
									data.items.count,
								);
							},
						})}
					/>
				</Stack>
			)}
		</TableDataProvider>
	);
};
