import React from 'react';

import { isPresent, Nullable } from '@common/typescript/objects/Nullable';

import { Filters } from '@app/components/UI/Filters/FilterHook';
import { onChangeValue, searchFieldClassname } from '@app/components/UI/Filters/FiltersList';
import { RouteSelect } from '@app/components/UI/Inputs/RouteSelect';
import { Route } from '@app/objects/Route';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';

export const getRouteSelect = (
	value,
	t,
	onChange: (name: keyof Filters, value: onChangeValue) => void,
	onDeselect: (fieldName: string, type: string, id?: number) => void,
	defaultParams?: Record<string, unknown>,
) => {
	const multiple = isPresent(defaultParams?.multiple) ? defaultParams?.multiple as boolean : true;

	return (
		<RouteSelect
			value={value}
			onChange={(value) => onChange('route', value)}
			onDeselect={(value: number) => onDeselect('route', 'id', value)}

			placeholder={t('route')}
			className={searchFieldClassname}
			selectClassName="select-with-inner-label--closed"
			activeClassName="select-with-inner-label--opened"
			isDefault={false}
			pickDefault={(store: Array<Route>) => isPickDefault(store)}
			multiple={multiple}
		/>
	);
};

export function getIsCustomerValue(value?: Nullable<number>): number | undefined {
	if (value === undefined) return undefined;

	return value === null ? -1 : value;
}
