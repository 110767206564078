export interface WithOrder {
	order: number;
}

/**
 Set order of items in array according to their current index
 */
export function reorder<T extends WithOrder>(array: Array<T>): Array<T> {
	return array.map((item: T, index: number) => ({ ...item, order: index }));
}

/**
 * Sort array according to order field of its elements
 * @param array
 */
export function sort<T extends WithOrder>(array: Array<T>): Array<T> {
	return array.sort((a: T, b: T) => a.order - b.order);
}

export function isWithOrder(item: WithOrder | unknown): item is WithOrder {
	return typeof (item as WithOrder).order === 'number';
}
