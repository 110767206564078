import React from 'react';
import { useTranslation } from 'react-i18next';

import Search from 'antd/lib/input/Search';
import {
	Field,
	FieldProps,
	Form,
	Formik,
} from 'formik';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Table, { TablePaginationConfig } from 'antd/lib/table';

import { List } from '@common/typescript/objects/List';
import { Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';

import {
	CopyOptions,
	CopyPriceActor,
	Price,
	PriceKind,
	PriceType,
} from '@app/objects/Price';
import { ModalWrapper } from '@app/components/UI/Modal/Modal';
import { ServiceType } from '@app/objects/Pet';
import { useRequest } from '@app/hooks/useRequest';
import { useDebounce } from '@app/hooks/useDebounce';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { serviceTypes, priceType as priceTypeOptions } from '@app/components/UI/Inputs/LocalSelectOptions';
import { useCrematory } from '@app/hooks/useCrematory';
import { getColumns } from '@app/components/Pages/Quickbooks/QuickbooksTabs/Items/QuickbooksItemEditor/InnerForm';
import { request } from '@app/components/Api';
import { MessageType, alertMessage } from '@app/utilities/alert';
import { useCountry } from '@app/hooks/useCountry';
import { australiaCode } from '@app/components/UI/Inputs/PhoneInput';

const pageSize = 10;
interface CopyPricesProps {
	closeDialog: () => void;
	reload: () => void;
	modalItemId: number | string;

	currentServiceType: ServiceType;
	currentPriceType: PriceType;
	priceKind: PriceKind;
	clinicId: Nullable<number>;
}

interface FormValues {
	id: Array<number>;
	selectAll: boolean;
}

export const copyPricesModalId = -2;
interface RequestParams {
	filters: {
		source: CopyOptions;
		destination: CopyOptions;
		clinicId: Nullable<number>;
		name: string;
		priceKind: PriceKind;
	},
	offset: number;
	count: number;
}

const checked = (selectAll: boolean, prices: Array<number>, priceId: number) => {
	let check = false;
	const item = prices.includes(priceId);

	if ((selectAll && item) || (!selectAll && !item)) check = false;

	if ((selectAll && !item) || (!selectAll && item)) check = true;

	return check;
};

const checkedColumn = TableBuilder.shape<Price>()
	.addColumn({
		title: (
			<Field name="selectAll">
				{({ field, form }: FieldProps<boolean, FormValues>) => (
					<Checkbox
						checked={field.value}
						onChange={(event: CheckboxChangeEvent) => {
							form.setFieldValue(field.name, event.target.checked);
							form.setFieldValue('id', []);
						}}
						indeterminate={Boolean(form.values.id.length)}
					/>
				)}
			</Field>
		),
		width: '10ch',
		align: 'center',
		dataIndex: 'check',
		render: (_, record: Price) => (
			<Field name="id">
				{({ field, form }: FieldProps<Array<number>, FormValues>) => (
					<Checkbox
						checked={checked(form.values.selectAll, field.value, record.id)}
						onChange={(event: CheckboxChangeEvent) => {
							const isChecked = event.target.checked;

							if (isChecked) {
								form.setFieldValue(field.name, [...field.value, record.id]);
							} else {
								form.setFieldValue(field.name, field.value.filter((i) => i !== record.id));
							}
						}}
					/>
				)}
			</Field>
		),
	})
	.build();

export const CopyPrices: React.FC<CopyPricesProps> = (props: CopyPricesProps) => {
	const {
		closeDialog,
		modalItemId,
		priceKind,
		currentServiceType,
		currentPriceType,
		clinicId,
		reload,
	} = props;
	const { t } = useTranslation();
	const crematory = useCrematory();
	const country = useCountry(crematory?.country);
	const isAustralia = React.useMemo(() => country?.phoneCode === australiaCode, [country]);
	const [query, setQuery] = React.useState<string>('');
	const [currentPage, setCurrentPage] = React.useState<number>(0);
	const [serviceType, setServiceType] = React.useState<ServiceType>(Number(serviceTypes(t, isAustralia, crematory)[0].value));
	const [priceType, setPriceType] = React.useState<PriceType>(PriceType.Retail);

	const destination = React.useMemo(() => ({
		serviceType: currentServiceType,
		priceType: currentPriceType,
	}), [currentServiceType, currentPriceType]);

	const requestParams: RequestParams = React.useMemo(() => ({
		filters: {
			destination,
			source: {
				serviceType,
				priceType,
			},
			clinicId,
			name: query,
			priceKind,
		},
		count: pageSize,
		offset: pageSize * currentPage,
	}), [currentPage, query, currentServiceType, currentPriceType, serviceType, priceType, clinicId, priceKind]);

	const data = useRequest<List<Price>, RequestParams>('availableForCopyPriceList', requestParams, { requestOnMount: false });

	const debounce = useDebounce((value: Record<string, unknown> | null) => {
		if (currentPage === 0) {
			data.reload({
				...requestParams,
				filters: {
					...requestParams.filters,
					source: {
						...requestParams.filters.source,
						...value,
					},
				},
			});
		} else {
			setCurrentPage(0);
		}
	}, 750);

	React.useEffect(() => {
		if (!data.loading && modalItemId === copyPricesModalId) data.reload(requestParams);
	}, [currentPage, modalItemId]);

	return (
		<Formik
			initialValues={{ id: [], selectAll: false }}
			onSubmit={(values, { setSubmitting }) => {
				request<CopyPriceActor>('copyPrice', {
					source: {
						serviceType,
						priceType,
						...values,
						name: query,
						priceKind,
					},
					destination: {
						...destination,
						clinicId,
					},
				})
					.then(() => {
						reload();
						alertMessage(MessageType.success, `${t('messages.type.success')}! ${t('page-headers.prices')} ${t('messages.text.copy')}`);
					})
					.catch((error) => alertMessage(MessageType.error, error))
					.finally(() => setSubmitting(false));
			}}
			enableReinitialize
		>
			{(formikBag) => (
				<ModalWrapper
					title="Copy Prices"
					isOpened={modalItemId === copyPricesModalId}
					onCancel={() => {
						closeDialog();
						formikBag.resetForm();
						setCurrentPage(0);
					}}
					onOk={formikBag.handleSubmit}
					okText="Copy"
					width="50%"
					okButtonProps={{ disabled: !formikBag.values.id.length && !formikBag.values.selectAll }}
				>
					<Form>
						<Search
							allowClear
							onChange={(event) => {
								if (event.target.value === '' && event.type === 'click') {
									setQuery('');
								}
							}}
							placeholder="Search by Name"
							onSearch={(value: string, event) => {
								event?.preventDefault();
								event?.stopPropagation();

								setQuery(value);

								if (currentPage === 0) {
									data.reload({ ...requestParams, filters: { ...requestParams.filters, name: value }, offset: 0 });
								} else {
									setCurrentPage(0);
								}
							}}
							style={{ padding: '0 0 10px' }}
							enterButton
						/>
						<div className="is-relative row">
							<LocalSelect
								value={serviceType}
								onChange={(value) => { setServiceType(value); debounce({ serviceType: value }); }}
								options={serviceTypes(t, isAustralia, crematory)}
								deselectType="id"
								filterName="serviceType"
								fieldName="Service type"
								placeholder={t('filters.service-type')}
								className="col-sm-6"
							/>
							<LocalSelect
								value={priceType}
								onChange={(value) => { setPriceType(value); debounce({ priceType: value }); }}
								options={priceTypeOptions(t)}
								deselectType="id"
								filterName="priceType"
								fieldName="Price type"
								placeholder={t('filters.price-type')}
								className="col-sm-6"
							/>
						</div>
						<Table
							columns={[...checkedColumn, ...getColumns(priceKind, t, crematory?.units.weight)]}
							dataSource={data.item?.list ?? []}
							onChange={(pagination: TablePaginationConfig) => {
								const page = (pagination.current ?? 1) - 1;
								setCurrentPage(page);
							}}
							pagination={{
								total: data.item?.count,
								pageSize,
								hideOnSinglePage: true,
								current: currentPage + 1,
							}}
							loading={data.loading}
							rowKey="id"
							className="table-mobile small-padding-table"
							onRow={(record: Price) => ({
								onClick: () => {
									const ids: Array<number> = formikBag.values.id;

									if (ids.includes(record.id)) {
										formikBag.setFieldValue('id', ids.filter((i) => i !== record.id));
									} else {
										formikBag.setFieldValue('id', [...ids, record.id]);
									}
								},
							})}
						/>
					</Form>
				</ModalWrapper>
			)}
		</Formik>
	);
};
