import * as React from 'react';

import { member } from '@common/react/utils/decorators';

interface ComponentState {
	visible: boolean;
	itemId: number | null;
}

// tslint:disable-next-line:class-name
export interface listWithModalHOCProps {
	visible: boolean;
	modalItemId: number | string;
	closeDialog: () => void;
	openDialog: (id: number | string) => void;
}

export function listWithModal(WrappedComponent) {
	class WithModalHOC extends React.Component<any, ComponentState> {
		public state: ComponentState = {
			visible: false,
			itemId: null,
		};

		render() {
			return (
				<WrappedComponent
					{...this.props}
					visible={this.state.visible}
					modalItemId={this.state.itemId}
					openDialog={this.openDialog}
					closeDialog={this.closeDialog}
				/>
			);
		}

		@member
		openDialog(id: number): void {
			this.setState({
				itemId: id,
				visible: true,
			});
		}

		@member
		closeDialog() {
			this.setState({
				visible: false,
				itemId: null,
			});
		}
	}

	return WithModalHOC;
}
