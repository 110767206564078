import { TFunction } from 'react-i18next';

import { DashboardCounterType, DashboardEntity } from '@app/objects/Dashboard';

export interface CounterEntity {
	type: DashboardCounterType;
	heading: string;
	name: 'statuses' | 'services' | 'reports';
	itemName: 'status' | 'service' | 'report';
	loadMoreEndpoint: string;
	availableEndpoint: string;
}

interface DashboardCounters {
	Status: CounterEntity;
	Service: CounterEntity;
	Report: CounterEntity;
}

export const dashboardEntities = (t: TFunction): DashboardCounters => ({
	Status: {
		type: DashboardCounterType.Status,
		heading: t('editors.dashboard-editor.entities.statuses'),
		name: 'statuses',
		itemName: t('editors.dashboard-editor.item-entities.status'),
		loadMoreEndpoint: 'dashboardStatusCounterList',
		availableEndpoint: 'AvailableDashboardStatusCounterList',
	},
	Service: {
		type: DashboardCounterType.Service,
		heading: t('editors.dashboard-editor.entities.special-services'),
		name: 'services',
		itemName: t('editors.dashboard-editor.item-entities.service'),
		loadMoreEndpoint: 'dashboardSpecialServiceCounterList',
		availableEndpoint: 'AvailableDashboardSpecialServiceCounterList',
	},
	Report: {
		type: DashboardCounterType.Report,
		heading: t('editors.dashboard-editor.entities.reports'),
		name: 'reports',
		itemName: t('editors.dashboard-editor.item-entities.report'),
		loadMoreEndpoint: 'dashboardReportCounterList',
		availableEndpoint: 'AvailableDashboardReportCounterList',
	},
});

export function getType(value: string): DashboardCounterType {
	if (value === 'statuses') return DashboardCounterType.Status;
	if (value === 'services') return DashboardCounterType.Service;

	return DashboardCounterType.Report;
}

export const filterDeletedItem = (values: Array<DashboardEntity>) => values.filter((i) => !i.deleted);

export interface IWithOrder {
	order: number;
}

type ReorderHandler = (from: number, to: number) => void;
type Setter<T> = (value: T) => void;
// Function to help reordering the result
export function getReorderHandler<TEntity extends IWithOrder>(list: Array<TEntity>, setter: Setter<Array<TEntity>>): ReorderHandler {
	return (from: number, to: number) => {
		const result = [...list];
		const source = result[from];
		const dest = result[to];

		result[from] = {
			...dest,
			order: source.order,
		};
		result[to] = {
			...source,
			order: dest.order,
		};

		setter(result);
	};
}
