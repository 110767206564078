import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tag } from 'antd';
import { CheckOutlined, CloseOutlined, LinkOutlined } from '@ant-design/icons';

import { Pagination } from '@common/react/smart components/Table/TableDataProvider';

import {
	CardSize,
	ClickableType,
	Field,
	getCardFields,
	ListComponent,
	Card,
} from '@app/components/UI/Cards/Card';
import { Clinic, ClinicType } from '@app/objects/Clinic';
import { Location } from '@app/objects/Location';
import { toWords } from '@app/components/Utils/Utils';
import { DeliveryType } from '@app/objects/Pet';
import { ClinicCrematory } from '@app/objects/ClinicCrematory';
import { UserRole } from '@app/objects/User';
import { ApplicationState } from '@app/store';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { selectListRequest } from '@app/store/SelectList/SelectListRequests';
import { RoleManager } from '@app/services/RoleManager';

export const generalInfoFields = (clinic: Clinic, userRole?: UserRole, clinicTypes?: Array<ClinicType>): Array<Field> => {
	const fields = [
		{ title: 'First Name', data: clinic.contactFirstName },
		{ title: 'Last Name', data: clinic.contactLastName },
		{ title: 'Phone number', data: clinic.phone, clickableType: ClickableType.Telephone },
		{ title: 'Email', data: clinic.email, clickableType: ClickableType.Email },
	];

	if (userRole && RoleManager.for(userRole).is([UserRole.Admin, UserRole.Crematory])) {
		const clinicType = clinicTypes?.find((i) => i.id === clinic?.clinicTypeId)?.name ?? '-';

		fields.push({ title: 'Clinic Type', data: clinicType });
	}

	return fields;
};

const linkedCrematoriesList = (clinicCrematories: Array<ClinicCrematory>) => clinicCrematories.map(
	(item) => {
		const status = item.isCustomer ? 'Customer' : 'Not a customer';
		const color = item.isCustomer ? 'green' : 'geekblue';

		return {
			id: item.crematoryId,
			title: item.crematory?.name ?? '',
			data: <Tag color={color}>{status}</Tag>,
		};
	},
);

const locationsList = (locations: Array<Location>) => locations.filter((item: Location) => !item.disabled)
	.map((item: Location) => (
		{
			id: item.clinicId,
			icon: (
				<>
					{item.externalId
						? <LinkOutlined title={item.externalId?.toString()} />
						: null}
				</>
			),
			title: item.name ? item.name : item.streetAddress,
			data: (
				<>
					{item.streetAddress}, {item.streetAddress2 ? `${item.streetAddress2}, ` : ''} {item.city || '-'},
					{item.state?.name || '-'}, {item.zip || '-'}
				</>
			),
		}));

export const addressFields = (clinic: Clinic) => [
	{ title: 'Country', data: `${clinic.country?.name ?? 'Unknown country'}` },
	{ title: 'Zip', data: clinic.zip },
	{ title: 'City', data: clinic.city },
	{ title: 'State', data: clinic.state },
	{ title: 'Street Address', data: clinic.streetAddress },
	{ title: 'Street Address 2', data: clinic.streetAddress2 },
];

export const localizationFields = (clinic: Clinic) => [
	{ title: 'Language', data: `${clinic.language?.name ?? 'Unknown language'}` },
	{ title: 'Date format', data: clinic.dateFormat },
	{ title: 'Time format', data: clinic.timeFormat },
];

const deliveryInfoFields = (clinic: Clinic, activeCrematoryId?: number, userRole?: UserRole) => {
	const clinicCrematory = clinic.clinicCrematories.find((i) => i.crematoryId === activeCrematoryId);
	const clinics = useSelector((state: ApplicationState) => state.selects.clinics.items);
	const deliveryClinic = clinics.find((i) => i.id === clinicCrematory?.deliveryClinicId)?.name;

	const result = [
		{
			title: 'Can set Delivery Type',
			data: clinic.canSetDeliveryType
				? <CheckOutlined className="color-green" />
				: <CloseOutlined className="color-red" />,
		},
		{ title: 'Default Communal Delivery Type', data: toWords(DeliveryType[clinic.defaultCommunalDeliveryType] ?? '') },
		{ title: 'Default Semiprivate Delivery Type', data: toWords(DeliveryType[clinic.defaultSemiPrivateDeliveryType] ?? '') },
		{ title: 'Default Private Delivery Type', data: toWords(DeliveryType[clinic.defaultPrivateDeliveryType] ?? '') },
	];

	if (userRole === UserRole.Crematory) {
		result.push(
			{
				title: 'Can select Custom Delivery Clinic',
				data: clinicCrematory?.canSelectDeliveryClinic
					? <CheckOutlined className="color-green" />
					: <CloseOutlined className="color-red" />,
			},
		);

		if (clinicCrematory?.canSelectDeliveryClinic) result.push({ title: 'Custom Delivery Clinic', data: deliveryClinic });
	}

	return result;
};

const preferencesFields = (clinic: Clinic) => [
	{
		title: 'The ability to put pet on Hold',
		data: clinic?.canPutOnHold
			? <CheckOutlined className="color-green" />
			: <CloseOutlined className="color-red" />,
	},
	{
		title: 'The ability to put pet on Rush',
		data: clinic?.canPutOnRush
			? <CheckOutlined className="color-green" />
			: <CloseOutlined className="color-red" />,
	},
];

export const GeneralInfoView: React.FC<{clinic: Clinic}> = ({ clinic }): JSX.Element => {
	const userRole = useSelector((state: ApplicationState) => state.login.user?.role);
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const tag = linkedCrematoriesList(clinic.clinicCrematories)
		.find((item) => item.id === activeCrematoryId)?.data;
	const dispatch = useDispatch();
	const factory = bindActionCreators(getActionCreators('clinicTypes', selectListRequest.clinicTypes), dispatch);
	const clinicTypes = useSelector((state: ApplicationState) => state.selects.clinicTypes.items);

	React.useEffect(() => {
		if (!clinicTypes.length && userRole && RoleManager.for(userRole).is([UserRole.Admin, UserRole.Crematory])) factory.request(null);
	}, [clinicTypes, userRole]);

	return (
		<>
			<Card
				name="General information"
				size={CardSize.Medium}
				fullWidth
				extra={userRole === UserRole.Crematory && tag}

				viewContent={getCardFields(generalInfoFields(clinic, userRole, clinicTypes))}
			/>
			<Card
				name="Address"
				size={CardSize.Medium}
				fullWidth
				withBtn

				viewContent={getCardFields(addressFields(clinic))}
				btnCaption="Show on map"
				onClick={() => window.open(`https://maps.google.com/maps?q=${clinic.streetAddress} 
				${clinic.streetAddress2} ${clinic.city} ${clinic.state} ${clinic.zip}`)}
			/>
			<Card
				name="Delivery Info"
				size={CardSize.Medium}
				fullWidth

				viewContent={getCardFields(deliveryInfoFields(clinic, activeCrematoryId, userRole))}
			/>
			<Card
				name="Localization Info"
				size={CardSize.Medium}

				viewContent={getCardFields(localizationFields(clinic))}
			/>
			{userRole === UserRole.Admin && (
				<Card
					name="Linked Crematories"
					size={CardSize.Medium}
					fullWidth

					viewContent={(
						<ListComponent
							list={linkedCrematoriesList(clinic.clinicCrematories)}
							placeholder="There are no linked crematories"
						/>
					)}
				/>
			)}
			<Card
				name="Preferences"
				size={CardSize.Medium}

				viewContent={getCardFields(preferencesFields(clinic))}
			/>
			<Card
				name="Notes"
				size={CardSize.Medium}
				fullWidth

				viewContent={<p>{clinic.note ? clinic.note : `No notes for ${clinic?.name}`}</p>}
			/>
		</>
	);
};

interface LocationsViewProps {
	locations: Array<Location>;
	pagination: Pagination;
}

export const LocationsView: React.FC<LocationsViewProps> = (props: LocationsViewProps): JSX.Element => (
	<Card
		name="Locations"
		size={CardSize.Medium}
		viewContent={<ListComponent list={locationsList(props.locations)} placeholder="There are no locations" pagination={props.pagination} />}

		fullWidth
	/>
);
